<template lang='pug'>
    #Cases.main-page.page-noScroll.position-relative
        loadingOverlay(v-show='loading' message='Loading . . .' :zIndex='999')
        //- win & lost start
        .position-absolute.close-left(v-show='showResult' :class='winDrag ? "dragoverStyle" : ""')
            h3.position-absolute.t-white.txt {{$t('CASE.CLOSED')}}
        .d-flex.justify-center.align-start.position-absolute.winbox(v-if='showResult')
            draggable(v-show='showResult' :list='loseList' :class='winDrag ? "hidden" : ""' v-on:drop.native='winDrag = false' v-on:dragleave.native='winDrag = false' v-on:dragover.native='winDrag = true' group="case_set" style ='height:100%; width: 100%;' @add='loseDrop()' )
        .position-absolute.close-right(v-show='showResult' :class='loseDrag ? "dragoverStyle" : ""')
            h3.position-absolute.t-white.txt {{$t('CASE.CLOSED')}}
        .d-flex.justify-center.align-start.position-absolute.losebox(v-if='showResult')
            draggable(v-show='showResult' :list='loseList' :class='loseDrag ? "hidden" : ""' v-on:drop.native='loseDrag = false' v-on:dragleave.native='loseDrag = false' v-on:dragover.native='loseDrag = true' group="case_set" style ='height:100%; width: 100%;' @add='loseDrop()')
        v-row(v-if='!loading')#page-head.align-center
            v-col.d-flex.align-center.justify-space-between.px-md-2.pb-md-4(cols='4' md='3' lg='4')
                .d-flex.align-center
                    h1.my-0(style='white-space:nowrap;') {{$t('CASE.TITLE')}}
                    v-btn.ml-1.add-deal-btn(@click='showAddCaseDialog()' width='40' height='40' :ripple='false' color='success' depressed dark fab)
                        v-icon(size='16') icon-add
                    v-btn.ml-1.add-deal-btn(v-if='mode == "list" && selectedRows.length>0' @click='showBulkEditDialog()' width='40' height='40' :ripple='false' color='success' depressed dark fab)
                        v-icon(size='16') icon-edit
                    v-btn.ml-1.add-deal-btn(v-if='mode == "list" && selectedRows.length == 1' @click='showCopyCaseDialog(selectedRows[0])' width='40' height='40' :ripple='false' color='success' depressed dark fab)
                        v-icon(size='16') far fa-copy
                    v-btn.ml-1.add-deal-btn(v-if='mode == "list" && selectedRows.length>0' @click='onDelete()' width='40' height='40' :ripple='false' color='success' depressed dark fab)
                        v-icon(size='16') icon-remove
                    v-btn.ml-1.add-deal-btn(v-if='selectedRows.length>0' @click='follow()' :title="$t('FOLLOW.CASE')" width='40' height='40' :ripple='false' color='success' depressed dark fab)
                        v-icon(size='20') mdi-star
                .btn-group(v-if='mode == "list"').ml-3
                    v-text-field(v-model='searchCases' height='36' flat dense solo prepend-inner-icon='icon-search')
            v-col.px-0.pt-4.pt-md-2(cols='8' md='9' lg='8')
                v-row.z-index-100.justify-end
                    v-col.btn-group-with-label.d-flex.justify-end(cols='3' lg='3')
                        .d-flex.justify-end.align-center
                            v-progress-circular(size='18' v-if='loading_content' indeterminate color='primary')
                            span(v-else-if='!loading_content && selectStageType.stage_type_name == $t("CASE.NO_CASE_STAGE")') 0 {{$t('CASE.TITLE')}}
                            span(v-else) {{ mode == 'list' ? pageDataCount : selectStageType.case_count}} {{$t('CASE.CASE')}}
                        .btn-switch.ml-3
                            v-btn(:ripple='false' @click='viewKanban' width='50%' height='36' :color=" mode == 'kanban'? 'primary':'white'"  depressed tile :dark='mode != "kanban"')
                                v-icon(size='16') icon-board
                            v-btn(:ripple='false' @click='viewList' width='50%' height='36' :color=" mode == 'list' ? 'primary' : 'white' " depressed tile :dark='mode != "list"')
                                v-icon(size='16') icon-list
                    v-col.pl-md-0.btn-group-with-label(cols='2' lg='2')
                        label {{$t('ADD_CASE.PROCESS')}}
                        .btn-group
                            v-combobox(v-model='selectStageType' :items='stageTypeList' item-value='id' item-text='stage_type_name' index='0' height='36' flat dense solo @change='reloadStageTypeAndData')
                            v-btn.border-left(:ripple='false' min-width='36' width='36' height='36' color='white' depressed tile dark @click='showAddStageDialog')
                                v-icon(size='16') icon-add
                    v-col.pl-md-1.btn-group-with-label(cols='2' lg='2')
                        label {{$t('CASE.OWNER')}}
                        v-combobox.bg-white(v-model='selectOwner' :items='ownerList' item-value='id' item-text='full_name' max-width='100' height='36' outlined dense @change='reloadData')
                    v-col.pr-md-0.pl-md-1.btn-group-with-label(cols='2' lg='2')
                        label {{$t('FILTERSETTING.CS')}}
                        .btn-group
                            setting_btn(:filter_list='filter_list' :tables='tables' :currentTable='currentTable' :default_filter='default_filter' tab='Case' :mode='mode' :slc-stg-type='selectStageType' :stage-type-list='stageTypeList' :is_staff='is_staff' @emitView='onEmitView' @emitFilter='onEmitFilter' :customViews='customViews')
                    v-col.pr-md-0.pl-md-1.d-flex.justify-end(cols='auto')
                        importExportBtn(@emitFilter='onEmitFilter' :page='"Case"' :selectStageType='selectStageType' :search='searchCases' :filter='default_filter.id' :tab='mode' :owner='selectOwner.id')
                        refreshBtn(@EmitRefresh='onEmitRefresh')
        #page-inner.scroll-x.pb-0.height_100vh(v-if="mode == 'kanban'")
            v-row.d-flex.justify-left.flex-nowrap.height_100
                v-col.d-flex(col='auto')
                    v-row(v-if="selectStageType.stage_type_name == $t('CASE.NO_CASE_STAGE')" height='100%') 
                        v-col.d-flex.align-center.justify-center.pa-0( height='100%')
                            v-sheet.d-flex.align-center.justify-center(rounded elevation="2" width='30%' height='30%')
                                h5.ma-0.t-black {{$t('CASE.NO_CASE_STAGE')}}
                    .pa-0.pr-2.px-md-2(v-for='subitem, index in selectStageType.stagedef_set' :key='index')
                        v-card.deal-group(v-if='!loading' min-width='280' max-width='460' outlined flat)
                            v-system-bar.customcolor(height='8' :style='getColor(subitem.stage_color)')
                            v-card-title.pa-0
                                v-badge(color='secondary' :content="subitem.case_count != undefined ? String(subitem.case_count) : '0' " inline)
                                    h4.ellipsis {{ subitem.stage_name }}
                            v-card-text(v-bind:id='"drag-" + subitem.id' v-if='!loading').pa-0
                                draggable.h-100(:list='subitem.case_set' group="case_set" draggable='.canDrag' animation=500 :key='subitem.id' @end='dragEnd()')
                                    v-card.deals-item(v-for='casesItem, index in subitem.case_set' :key='index' flat :class=' canDrag_kanban ? "canDrag":"" ' @dragstart='dragstart(casesItem)')
                                        v-card-title.d-flex.align-center.justify-space-between.pa-0
                                            v-row.ellipsis
                                                v-col.d-flex.align-center.px-0(cols='10')
                                                    h5.ellipsis(:title='casesItem.subject') {{ casesItem.subject }}
                                                v-tooltip(bottom)
                                                    template(v-slot:activator='{ on, attrs }')
                                                        v-col(cols='2').d-flex.justify-end.align-center.px-0
                                                            v-btn.border-none.customcolor.mr-1(width='14' height='14' :style='getColor(casesItem.stage_light_color.color)' v-bind='attrs' v-on='on' fab depressed)
                                                    span {{ casesItem.stage_light_color.tip }}
                                        v-card-text.pa-0(@click='showToCaseDialog(casesItem)')
                                            v-chip.my-2.text-white(v-if="casesItem.status != null" color='primary' small lighten-3) {{ getItemNameFromList(caseStatusList, casesItem.status) }}
                                            p.mt-1.mb-0.ellipsis(:title="casesItem.case_number") {{$t('CASE.CASE_NUMBER')}}：{{ casesItem.case_number != null ? casesItem.case_number : $t('GENERAL.NONE') }}
                                            v-row.d-flex.align-center(no-gutters)
                                                v-col(cols='12')
                                                    v-icon(v-if="casesItem.priority_number == '5'" size='18' color='red') fas fa-angle-double-up
                                                    v-icon(v-if="casesItem.priority_number == '4'" size='18' color='red') fas fa-angle-up
                                                    v-icon(v-if="casesItem.priority_number == '3'" size='18' color='orange') fas fa-equals
                                                    v-icon(v-if="casesItem.priority_number == '2'" size='18' color='primary') fas fa-angle-down
                                                    v-icon(v-if="casesItem.priority_number == '1'" size='18' color='primary') fas fa-angle-double-down
                                                    span.ml-2(v-if="casesItem.priority_number != null") {{ getItemNameFromList(casePriorityList, casesItem.priority_number) }}
                                                v-col.text-left.ellipsis(cols='9')
                                                    span.mr-2(:title="casesItem.owner.last_name+' '+casesItem.owner.first_name") {{$t('CASE.OWNER')}}：{{ casesItem.owner.last_name+' '+casesItem.owner.first_name }}
                                                v-col.text-right(cols='3')
                                                    v-avatar(color='aliceBlue' size='28')
                                                        span(v-if="!casesItem.owner.usersetting.photo") {{ casesItem.owner.last_name }}
                                                        img(v-else :src='casesItem.owner.usersetting.photo' :alt='casesItem.owner.last_name')
                            v-card-actions.text-center
                                v-btn.mx-auto(@click='showAddCaseDialog(subitem)' :ripple='false' width='32' height='32' color='grey lighten-1' depressed dark fab='fab')
                                    v-icon(size='16') icon-add
        #page-inner.scroll-x.pb-0.height_100vh(v-if='mode == "list"')
            .data-area
                v-data-table.deals-table(v-if='!loading_content' :headers='casesHeaders' :header-props="{sortIcon: 'mdi mdi-arrow-up'}" :items='caseList' :items-per-page='itemPerPage' :page.sync='page' :page-count='pageCount' :sort-desc.sync='sortByDesc' multi-sort
        :sort-by.sync="sortBy" height='100%' hide-default-footer='hide-default-footer' :loading='loading_edit' loading-text='loading...' fixed-header v-model="selectedRows" show-select item-key='id'  v-resize-table="headerData")
                    
                    //- 客製化欄位
                    template(v-for='e, index in cf_header' v-slot:[`item.${e.value}`]='{ item }')
                        div(v-if='item.custom_field_list != null' class='d-flex justify-center')
                            .inline-edit-cell
                                v-icon(v-if='e.type == "bol" && item.custom_field_list[`${e.prop}`] === true' class='ellipsis ml-8' size="18" color="green" ) fas fa-check 
                                span(class='ellipsis ml-8' v-else-if='e.type == "bol" && item.custom_field_list[`${e.prop}`] === false')
                                span(class='ellipsis ml-8' v-else-if='e.type == "opt" || e.type == "tst"' :title='getItemNameFromList(CFdef[`${e.prop}`].optList, item.custom_field_list[`${e.prop}`])') {{ getItemNameFromList(CFdef[`${e.prop}`].optList, item.custom_field_list[`${e.prop}`]) }}
                                span(class='ellipsis ml-8' v-else-if='e.type == "mlt"' :title='getCustomfieldOptItemNameFromList(CFdef[`${e.prop}`].optList, item.custom_field_list[`${e.prop}`])') {{ getCustomfieldOptItemNameFromList(CFdef[`${e.prop}`].optList, item.custom_field_list[`${e.prop}`]) }}
                                span(class='ellipsis ml-8' v-else :title='item.custom_field_list[`${e.prop}`]') {{ item.custom_field_list[`${e.prop}`] }}
                            inlineEditDialog(v-if='e.type != "opt" && e.type != "mlt" && e.type != "tst"' page='cases' :field_type='"custom_field_"+e.type' :editProp='e.prop' :editItem='item' :cf_mandatory='CFdef[`${e.prop}`].mandatory' @emitInlineEdit='onEmitInlineEdit_cf')
                            inlineEditDialog(v-if='e.type == "opt"' page='cases' field_type='custom_field_opt' :editProp='e.prop' :editItem='item' :cf_mandatory='CFdef[`${e.prop}`].mandatory' :selectList='CFdef[`${e.prop}`].optList' item-text="name" item-value="key" @emitInlineEdit='onEmitInlineEdit_cf')
                            inlineEditDialog(v-if='e.type == "mlt"' page='cases' field_type='custom_field_mlt' :editProp='e.prop' :editItem='item' :cf_mandatory='CFdef[`${e.prop}`].mandatory' :selectList='CFdef[`${e.prop}`].optList' item-text="name" item-value="key" @emitInlineEdit='onEmitInlineEdit_cf')
                            inlineEditDialog(v-if='e.type == "tst"' page='cases' field_type='custom_field_tst' :editProp='e.prop' :editItem='item' :cf_mandatory='CFdef[`${e.prop}`].mandatory' :selectList='CFdef[`${e.prop}`].treeList' item-text="name" item-value="key" @emitInlineEdit='onEmitInlineEdit_cf' :treeSelectList='CFdef[`${e.prop}`].optList')
                    //- 優先等級
                    template(v-slot:item.priority_number='{ item }')
                         div(class='d-flex justify-center')
                            .inline-edit-cell
                                v-icon(class='ellipsis ml-8' v-if="item.priority_number == '5'" :title='getItemNameFromList(casePriorityList, item.priority_number)' size='20' color='red') fas fa-angle-double-up
                                v-icon(class='ellipsis ml-8' v-if="item.priority_number == '4'" :title='getItemNameFromList(casePriorityList, item.priority_number)' size='20' color='red') fas fa-angle-up
                                v-icon(class='ellipsis ml-8' v-if="item.priority_number == '3'" :title='getItemNameFromList(casePriorityList, item.priority_number)' size='20' color='orange') fas fa-equals
                                v-icon(class='ellipsis ml-8' v-if="item.priority_number == '2'" :title='getItemNameFromList(casePriorityList, item.priority_number)' size='20' color='primary') fas fa-angle-down
                                v-icon(class='ellipsis ml-8' v-if="item.priority_number == '1'" :title='getItemNameFromList(casePriorityList, item.priority_number)' size='20' color='primary') fas fa-angle-double-down
                            inlineEditDialog(field_type='select' page='cases' editProp='priority_number' :editItem='item' :selectList='casePriorityList' item-text="name" item-value="key" @emitInlineEdit='onEmitInlineEdit')

                    //- 聯絡人
                    template(v-slot:item.primary_contact='{ item }')
                        div(class=' d-flex justify-left position-relative' :title='item.primary_contact.name ? item.primary_contact.name :item.primary_contact.full_name' )
                            .inline-edit-cell
                                a.pl-2(class='ml-8 ellipsis' :title='item.primary_contact.name ? item.primary_contact.name :item.primary_contact.full_name' :href='"/Contacts/" + item.primary_contact.id' ) {{ item.primary_contact.name ? item.primary_contact.name :item.primary_contact.full_name }}
                            inlineEditDialog(field_type='select_required_contact' page='cases' editProp='primary_contact' :editItem='item' @emitInlineEdit='onEmitInlineEdit')

                    //- 公司
                    template(v-slot:item.case_org='{ item }')
                        div(v-if='item.case_org != null' class=' d-flex justify-left position-relative' :title='item.case_org.name' )
                            .inline-edit-cell
                                a.pl-2(class='ellipsis ml-8' v-if='item.case_org' :title='item.case_org.name' :href='"/Account/" + item.case_org.id' ) {{ item.case_org.name }}
                            inlineEditDialog(field_type='select_org' page='cases' editProp='case_org' :editItem='item' @emitInlineEdit='onEmitInlineEdit')

                    //- 狀態
                    template(v-slot:item.status='{ item }')
                        div(class='d-flex justify-center' :title='item.statusName')
                            .inline-edit-cell
                                v-chip.my-2.text-white(class='ellipsis ml-8' v-if='item.statusName == $i18n.t("CASESTATUSLIST.RESOLVED")' color='green' small) {{ item.statusName }}
                                v-chip.my-2.text-white(class='ellipsis ml-8' v-else-if='item.statusName == $i18n.t("CASESTATUSLIST.CLOSED")' color='primary' small) {{ item.statusName }}
                                v-chip.my-2.text-black(class='ellipsis ml-8' v-else small) {{ item.statusName }}
                            inlineEditDialog(field_type='select' page='cases' editProp='status' :editItem='item' :selectList='caseStatusEditList' item-text="name" item-value="key" @emitInlineEdit='onEmitInlineEdit')

                    //- 標題
                    template(v-slot:item.subject='{ item }')
                        div(class='d-flex justify-left' :title='item.subject')
                            followIcon(:follow='item.follow')
                            .inline-edit-cell.justify-start
                                a(class='ellipsis ml-8' :title='item.subject' :href='"/Case/" + item.id') {{ item.subject }}
                            inlineEditDialog(field_type='casename' page='cases' editProp='subject' :editItem='item' @emitInlineEdit='onEmitInlineEdit')

                    //- 母案件
                    template(v-slot:item.parent_case_id='{ item }')
                        div(class='d-flex justify-center ' :title='item.parent_case.subject')
                            .inline-edit-cell
                                a(class='ellipsis ml-8' :title='item.parent_case.subject' :href='"/Case/" + item.parent_case_id' ) {{ item.parent_case.subject }}
                            inlineEditDialog(field_type='select_parent_case' page='cases' editProp='parent_case' :editItem='item' @emitInlineEdit='onEmitInlineEdit')

                    //- 階段
                    template(v-slot:item.current_stage='{ item }')
                        div(class='d-flex justify-center ' :title='item.stage_name')
                            .inline-edit-cell
                                span(class='ellipsis ml-8' :title='item.stage_name') {{ item.stage_name }}
                            inlineEditDialog(field_type='select' page='cases' editProp='current_stage_id' :editItem='item' :selectList='selectStageTypeList' item-text="stage_name" item-value="id" @emitInlineEdit='onEmitInlineEdit')

                    //- 組別
                    template(v-slot:item.group='{ item }')
                        div(class='d-flex justify-center' :title='item.groupName')
                            .inline-edit-cell
                                span(class='ellipsis ml-8' v-if='item.groupName') {{ item.groupName }}
                            inlineEditDialog(field_type='select' page='cases' editProp='group' :editItem='item' :selectList='caseGroupList' item-text="group_name" item-value="id" @emitInlineEdit='onEmitInlineEdit')

                    //-類別
                    template(v-slot:item.type='{ item }')
                        div(class='d-flex justify-center' :title='item.typeName')
                            .inline-edit-cell
                                span(class='ellipsis ml-8' v-if='item.typeName') {{ item.typeName }}
                            inlineEditDialog(field_type='select' page='cases' editProp='type' :editItem='item' :selectList='caseTypeList' item-text="type_name" item-value="id" @emitInlineEdit='onEmitInlineEdit')

                    //- 客服人員
                    template(v-slot:item.owner='{ item }')
                        div(class=' d-flex justify-left position-relative' :title='item.owner.last_name+" "+item.owner.first_name' )
                            v-list-item-group(class='inline-edit-cell justify-start')
                                v-list-item-avatar.d-inline-block.ma-0.mx-4(color="aliceBlue", size="40")
                                    v-icon(v-if="item.owner == null") mdi-account 
                                    v-icon(v-else-if="item.owner.usersetting.photo == null") mdi-account
                                    img(v-else :src='item.owner.usersetting.photo')
                                a(v-if='!!item.owner' :href='onMail(item.owner.email)' class='ellipsis') {{ item.owner.last_name+' '+item.owner.first_name }}
                            inlineEditDialog(field_type='select' page='cases' editProp='owner' :editItem='item' :selectList='ownerList_inlineEdit' item-text="full_name" @emitInlineEdit='onEmitInlineEdit')

                    //- 案件流程
                    template(v-slot:item.stage_type='{ item }')
                        .ellipsis
                            .t-black(:title='item.stage_type_name' v-if='item.stage_type_name') {{ item.stage_type_name }}
                    //- 案件編號
                    template(v-slot:item.case_number='{ item }')
                        div(class='d-flex justify-center align-center' :title='item.case_number')
                            .inline-edit-cell
                                a.pl-2(:title='item.case_number' :href='"/Case/" + item.id' ) {{ item.case_number }}
                            inlineEditDialog(v-if="!autoNumberCheck" field_type='text_len50' page='cases' editProp='case_number' :editItem='item' @emitInlineEdit='onEmitInlineEdit')

                    //- 預計結案日
                    template(v-slot:item.due_date='{ item }')
                        div(class='d-flex justify-center ' :title='item.due_date')
                            .inline-edit-cell
                                span(class='ellipsis ml-8' :title='item.due_date' v-if='item.due_date') {{ item.due_date }}
                            inlineEditDialog(field_type='m_date' page='cases' editProp='due_date' :editItem='item' @emitInlineEdit='onEmitInlineEdit')

                    //- 進線聯絡人
                    template(v-slot:item.incoming_fullname='{ item }')
                        div(class='d-flex justify-center align-center' :title='item.incoming_fullname')
                            .inline-edit-cell
                                span(class='ellipsis ml-8' :title='item.incoming_fullname' v-if='item.incoming_fullname') {{ item.incoming_fullname }}
                            inlineEditDialog(field_type='text_len50' page='cases' editProp='incoming_fullname' :editItem='item' @emitInlineEdit='onEmitInlineEdit')

                    //- 進線電話
                    template(v-slot:item.incoming_phone='{ item }')
                        div(class=' d-flex justify-left position-relative' :title='item.mobile_phone' )
                            .inline-edit-cell 
                                a.t-primary(v-if='item.incoming_phone != null && item.incoming_phone != ""' class='ellipsis ml-8' :title='item.incoming_phone' :href='onCall(item.incoming_phone)') {{ item.incoming_phone }}
                            inlineEditDialog(field_type='mobile_phone' page='cases' editProp='incoming_phone' :editItem='item' @emitInlineEdit='onEmitInlineEdit')

                    //- 進線電郵
                    template(v-slot:item.incoming_email='{ item }')
                        div(class=' d-flex justify-left position-relative' :title='item.incoming_email' )
                            .inline-edit-cell 
                                a.t-primary(class='ellipsis ml-8' :title='item.incoming_email' :href='onMail(item.incoming_email)') {{ item.incoming_email }}
                            inlineEditDialog(field_type='email' page='cases' editProp='incoming_email' :editItem='item' @emitInlineEdit='onEmitInlineEdit')

                    //- 案件來源
                    template(v-slot:item.source='{ item }')
                        div(class='d-flex justify-center' :title='item.source')
                            .inline-edit-cell
                                span(class='ellipsis ml-8' :title='getItemNameFromList(caseSourceList, item.source)' v-if='item.source') {{ getItemNameFromList(caseSourceList, item.source) }}
                            inlineEditDialog(field_type='select' page='cases' editProp='source' :editItem='item' :selectList='caseSourceList' item-text="name" item-value="key" @emitInlineEdit='onEmitInlineEdit')
                    //- 結案日期
                    template(v-slot:item.closed_date='{ item }')
                        .ellipsis
                            .t-black(:title='item.closed_date' v-if='item.closed_date') {{ new Date(item.closed_date).toLocaleString() }}
                    //- 結案原因
                    template(v-slot:item.closed_reason='{ item }')
                        .ellipsis 
                            .t-black(:title='getItemNameFromList( closeCaseReasonList, item.closed_reason)' v-if='item.closed_reason') {{ getItemNameFromList( closeCaseReasonList, item.closed_reason) }}
                    //- 相關活動
                    template(v-slot:item.primary_campaign_id='{ item }')
                        div(class='d-flex justify-center ' :title='item.primary_campaign_name')
                            .inline-edit-cell
                                a(class='ellipsis ml-8' v-if='item.primary_campaign_id' :href='"Campaign/"+item.primary_campaign_id' target="_blank") {{ getItemNameFromListById(campaignList, item.primary_campaign_id) }}
                            inlineEditDialog(field_type='autocomplete' page='cases' editProp='primary_campaign_id' :selectList='campaignList' item-text="name" item-value="id" :editItem='item' @emitInlineEdit='onEmitInlineEdit')
                    //- 建立時間
                    template(v-slot:item.created_on='{ item }')
                        .ellipsis
                            .t-black(:title='item.created_on' v-if='item.created_on') {{ new Date(item.created_on).toLocaleString() }}
                    //- 建立者
                    template(v-slot:item.created_by='{ item }')
                        div(class=' d-flex justify-left position-relative ml-4' :title='item.created_by.last_name+" "+item.created_by.first_name' )
                            v-list-item-group(class='inline-edit-cell justify-start')
                                v-list-item-avatar.d-inline-block.ma-0.mx-4(color="aliceBlue", size="40")
                                    v-icon(v-if="item.created_by == null") mdi-account 
                                    v-icon(v-else-if="item.created_by.usersetting.photo == null") mdi-account
                                    img(v-else :src='item.created_by.usersetting.photo')
                                a(v-if='!!item.created_by' :href='onMail(item.created_by.email)' class='ellipsis') {{ item.created_by.last_name+' '+item.created_by.first_name }}
                    //- 更新時間
                    template(v-slot:item.updated_on='{ item }')
                        .ellipsis
                            .t-black(:title='item.updated_on' v-if='item.updated_on') {{ new Date(item.updated_on).toLocaleString() }}
                    //- 更新者
                    template(v-slot:item.updated_by='{ item }')
                        div(class=' d-flex justify-left position-relative ml-4' :title='item.updated_by.last_name+" "+item.updated_by.first_name' )
                            v-list-item-group(class='inline-edit-cell justify-start')
                                v-list-item-avatar.d-inline-block.ma-0.mx-4(color="aliceBlue", size="40")
                                    v-icon(v-if="item.updated_by == null") mdi-account 
                                    v-icon(v-else-if="item.updated_by.usersetting.photo == null") mdi-account
                                    img(v-else :src='item.updated_by.usersetting.photo')
                                a(v-if='!!item.updated_by' :href='onMail(item.updated_by.email)' class='ellipsis') {{ item.updated_by.last_name+' '+item.updated_by.first_name }}

                    //- 產品
                    template(v-slot:item.product='{ item }')
                        .ellipsis.t-black(:title='item.product' v-if='item.product_name') {{ item.product_name }}
                    
                    //- 案件描述 description
                    template(v-slot:item.description='{ item }')
                        div(class='d-flex justify-center ' :title='item.description')
                            .inline-edit-cell
                                span(class='ellipsis ml-8') {{item.description}}
                            inlineEditDialog(field_type='textarea' page='cases' editProp='description' :editItem='item' @emitInlineEdit='onEmitInlineEdit')
                    //- 案件處理 resolution
                    template(v-slot:item.resolution='{ item }')
                        div(class='d-flex justify-center ' :title='item.resolution')
                            .inline-edit-cell
                                span(class='ellipsis ml-8') {{item.resolution}}
                            inlineEditDialog(field_type='textarea' page='cases' editProp='resolution' :editItem='item' @emitInlineEdit='onEmitInlineEdit')
                    //- 執行&燈號
                    template(v-slot:item.action='{ item }')
                        //- v-system-bar(:color='item.stage_light_color.color')
                        .ellipsis
                            v-btn( icon='icon' @click='showToCaseDialog(item)')
                                v-icon(size='18') mdi-folder-wrench
                            v-btn(icon='icon' @click='onDelete(item)')
                                v-icon(size='18') icon-remove
                label.text-right.mr-4(v-if='!loading_content') {{(page-1)*itemPerPage+1}} － {{currentData}} of {{pageDataCount}} 
                v-pagination.py-1.mb-3(v-if='!loading_content' v-model='page' :length='pageCount' circle :total-visible="10") 
        v-navigation-drawer.v-navigation-drawer-bulk-edit(v-model='bulkEditDialog'  v-if='bulkEditDialog' absolute bottom temporary right width="400" zIndex='99999')
            bulk-edit-dialog(@emitBulkEditDialog='onEmitBulkEditDialog' :editItems='selectedRows' :stageTypeId='selectStageType' :autoNumberCheck='autoNumberCheck')
        v-dialog(v-model='addCaseDialog' max-width='600' v-if='addCaseDialog')
            add-case(@emitAddCaseDialog='onEmitAddCaseDialog' @emitNewCaseData='onEmitNewCaseData' :add-case='addCaseData')
        v-dialog(v-model='toCaseDialog' max-width='1200' v-if='toCaseDialog' persistent @keydown.esc='onEmitToCaseDialog(false)')
            to-case(@emitToCaseDialog='onEmitToCaseDialog' :case-data='caseData')
        v-dialog(v-model='addProcessDialog' max-width='500' content-class='setting-dialog' v-if='addProcessDialog' scrollable)
            add-process-dialog(@emitAddProcessDialog='onEmitAddProcessDialog' @emitAddProcessData='onEmitAddProcessData' :stage-type='stageTypeData' :stage-def='stageDefData' :pageType='pageType')
        v-dialog(v-model='messageDialog' width='360' content-class='statusDialog')
            message-dialog(@emitMessageDialog='onEmitMessageDialog' :message='message')
        v-dialog(v-model='successDialog' width='360' content-class='statusDialog')
            success-dialog(@emitSuccessDialog='onEmitSuccessDialog')
        v-dialog(v-model='errorDialog' width='360' content-class='statusDialog')
            error-dialog(@emitErrorDialog='onEmitErrorDialog' :errorMessage='errorMessage')
        v-dialog(v-model='loseReason' width='400' v-if='loseReason' persistent @keydown.esc='onEmitLoseReasonDialog(false)')
            lose-reason(@emitLoseReasonDialog='onEmitLoseReasonDialog' :lose-deal='loseDealData')
        v-dialog(v-model='editTableDialog' width='360' content-class='statusDialog' v-if='editTableDialog')
            edit-table-dialog(@emitEditTableDialog='onEmitEditTableDialog' @emitEditTable='onEmitEditTable' :custom-views='customViews' :edit-data='currentTable' :stage-type='selectStageType' :edit-mode='editMode')
</template>

<script>
    // dialog
    import loseReason from '@/components/Dialog/Deal/loseReason';
    import addCase from '@/components/Dialog/Case/addCase';
    import toCase from '@/components/Dialog/Case/toCase';
    import messageDialog from '@/components/Dialog/messageDialog';
    import successDialog from '@/components/Dialog/successDialog';
    import errorDialog from '@/components/Dialog/errorDialog';
    import addProcessDialog from '@/components/Dialog/Setting/addProcessDialog';
    import editTableDialog from "@/components/Dialog/Setting/editTableDialog";
    import bulkEditDialog from "@/components/Dialog/BulkEdit/caseBulkEditDialog";
    import inlineEditDialog from '@/components/Common/inlineEditDialog';
    // service
    import OrganizationDataService from "@/services/OrganizationDataService";
    import StageDataService from "@/services/StageDataService"
    import CaseDataService from "@/services/CaseDataService";
    import CustomViewDataService from "@/services/CustomViewDataService";
    import CustomFilterDataService from "@/services/CustomFilterDataService";
    import CustomFieldDataService from "@/services/CustomFieldDataService";
    import AutoNumberService from "@/services/AutoNumberService";
    import CampaignDataService from "@/services/CampaignDataService";
    import BulkEditDataService from "@/services/BulkEditDataService";
    import FollowDataService from '../services/FollowDataService';
    import TreeOptionsDataService from '@/services/TreeOptionsDataService';
    // list
    import caseStatusList from"@/array/the_caseStatusList";
    import casePriorityList from "@/array/the_casePriorityList";
    import casesFieldList from"@/array/the_casesFieldList";
    import caseSourceList from"@/array/the_caseSourceList";
    import closeCaseReasonList from"@/array/the_closeCaseReasonList";
    // other
    import refreshBtn from "@/components/Common/refreshBtn";
    import draggable from 'vuedraggable';
    import loadingOverlay from "@/components/Common/loadingOverlay";
    import i18n from '/common/plugins/vue-i18n.js'  
    import importExportBtn from "@/components/Common/importExportBtn";
    import setting_btn from "@/components/Common/setting_btn";
    import followIcon from "@/components/Common/followIcon";

    export default {
        name: 'Cases',
        components: {
            // dialog
            addCase,
            toCase,
            addProcessDialog,
            messageDialog,
            successDialog,
            errorDialog,
            loseReason,
            editTableDialog,
            bulkEditDialog,
            // other
            draggable,
            loadingOverlay,
            refreshBtn,
            importExportBtn,
            setting_btn,
            inlineEditDialog,
            followIcon,
        },
        data() {
            return {
                loading_edit: false,
                is_staff: JSON.parse(window.localStorage.getItem('is_staff')),
                user: JSON.parse(window.localStorage.getItem('user')),
                loading: true,
                loading_content: true,
                mode: 'kanban',
                awaitingSearch: false,
                sortBy:[],
                sortByForSearch:[],
                sortByDesc:[],
                pageDataCount:null,
                currentData:0,
                page: 1,
                pageCount: 0,
                itemPerPage: 50,
                total_cases:0,
                selectStageType: {},
                selectOwner: {},
                searchCases: '',
                casesHeaders: [],                
                updateCase: {},
                msgDialog_mode: '',
                stageTypeData: {},
                pageType:"",
                caseData: {},
                addCaseData: {},
                deleteItem: {},
                canDrag_kanban: true,
                // list
                caseList: [],
                stageTypeList: [],
                priority_list: [
                    'VERY_HIGH',
                    'HIGH',
                    'MEDIUM',
                    'LOW',
                    'VERY_LOW',
                ],
                caseGroupList: [],
                caseTypeList: [],
                ownerList: [
                    {
                        id: 0,
                        full_name: i18n.t('GENERAL.ALL')
                    }
                ],
                caseStatusList: caseStatusList,
                caseStatusEditList : [],
                casePriorityList: casePriorityList,
                caseSourceList: caseSourceList,
                closeCaseReasonList: closeCaseReasonList,
                stageDefData: [],
                tables: [],
                editTableDialog: false,
                currentTable: {},
                customViews: [],
                cf_header: [],
                editMode: 'EDIT',
                // win & lost
                winList:[],
                loseList: [],
                loseReason: false,
                loseDealData: {},
                dragStageIndex: 0,
                isWin: false,
                isLose: false,
                showResult: false,
                winDrag: false,
                loseDrag: false,
                winLost:false,
                // dialog
                toCaseDialog:false,
                hadEditToCase:false,
                addCaseDialog: false,
                messageDialog: false,
                message: '',
                successDialog: false,
                errorDialog: false,
                errorMessage: '',
                addProcessDialog: false,
                getnewData:false,
                default_filter:null,   
                filter_list : [],
                first_run:false,
                allfilter_list:[],
                roll_set:{},
                system_default_filter: null,
                selectedRows: [],
                bulkEditDialog:false,
                customField:null,
                campaignList: null,
                CFdef: null,
                autoNumberCheck:null,
                ownerList_inlineEdit: [],
                selectStageTypeList:[],
                changeListStage:false,
                deleteCaseIds:"",
                headerData:{
                    id:"",
                    name:"",
                    value:{},
                    page:""
                },
            }
        },
        async created() {
            // get default process
            let default_process = null;
            try {
                default_process = this.user.default_value.default_process;
            } 
            catch (error) {
                default_process = null;
            }
            
            await (this.loading = true);

            await CaseDataService.getcasegrouplist()
            .then(response => {
                this.caseGroupList = response.data;
            });

            await CaseDataService.getcasetypelist()
            .then(response => {
                this.caseTypeList = response.data;
            });
            await AutoNumberService.list()
            .then(response => {
                this.autoNumberCheck = response.data.filter(el => {return el.using_page == "CASE"})[0].active;
            })
            .catch((response) => {
                this.showErrorDialog(response);
            });
            await (this.caseStatusEditList = this.caseStatusList.filter(el=>el.key!="CLOSED"))
            await this.getCampaginList()
            await OrganizationDataService.getchangeowner('cases')
            .then(response => {
                response.data.forEach(item => {
                    if (item.first_name != null) {
                        item.full_name = item.last_name + " " + item.first_name;
                    } 
                    else {
                        item.full_name = item.last_name;
                    }
                    this.ownerList.push(item);
                    this.ownerList_inlineEdit.push(item);
                });
                this.selectOwner = this.ownerList[0];
            })
            .catch(response => {
                this.showErrorDialog(response)
            })

            await StageDataService.stageList('case')
            .then(response => {
                this.stageTypeList = response.data;
                if(this.stageTypeList.length == 0)
                {
                    let item = {};
                    item.stage_type_name = i18n.t('CASE.NO_CASE_STAGE');
                    this.stageTypeList.push(item);
                }
                this.stageTypeList.forEach(item => {
                    if(default_process == null || default_process.case == null){
                        item.is_admin_default == true ? this.selectStageType = item : '';
                    }
                    else if(item.id == default_process.case){
                        this.selectStageType = item;
                    }
                })
                try{
                    this.selectStageTypeList = this.selectStageType.stagedef_set;
                }catch{
                    this.selectStageTypeList = [];
                }
            })
            .catch(response => {
                this.showErrorDialog(response);
            })

            let filter_id = this.getDefaultFilter("Case",this.selectStageType.id);
            await this.getFilterList();
            await (this.default_filter = this.getPageDefaultFilter(filter_id,this.filter_list,this.system_default_filter));

            await CustomViewDataService.getCustomView('Case')
            .then(response => {
                this.customViews = response.data;
            })
            .catch(response => {
                this.showErrorDialog(response);
            })

            await this.getTables();
            await (this.first_run = true);
        },
        methods: {
            follow(){
                let add_list=[];
                let delete_list=[];
                for(let i = 0; i < this.selectedRows.length; i++)
                    {
                    if(!this.selectedRows[i].follow){
                        add_list.push(this.selectedRows[i].id);
                    }else{
                        delete_list.push(this.selectedRows[i].id)
                    }
                    }
                let data = {
                    "add_list":add_list,
                    "delete_list":delete_list,
                    "class_name":"Case"
                }
                FollowDataService.bulkCreateDeleteFollow(data)
                .then((response)=>{
                    for(let i in response.data.add_list){
                    let case_data = this.caseList.filter(el => el.id == response.data.add_list[i]);
                    case_data[0].follow = true;
                    }
                    for(let i in response.data.delete_list){
                    let case_data = this.caseList.filter(el => el.id == response.data.delete_list[i]);
                    case_data[0].follow = false;
                    }
                    this.selectedRows = [];
                })
            },
            getCampaginList(){
                CampaignDataService.selectlist()
                .then((response)=>{
                    this.campaignList = response.data;
                })
            },
            onEmitInlineEdit(listItem, editItem, prop, value){
                this.loading_edit = true;
                // 更新階段
                if(prop == "current_stage_id"){
                    let stageData ={"current_stage_id":value}
                    CaseDataService.updatestage(editItem.id, stageData)
                    .then((response)=>{
                        let stageName = this.selectStageTypeList.filter(el=>el.id==response.data.current_stage_id)[0].stage_name
                        listItem.current_stage_id = value;
                        listItem.current_stage = {"stage_name":stageName};
                        listItem.stage_name = stageName;
                    })
                    .catch(response =>{
                        this.showErrorDialog(response);
                    })
                    .finally(()=>{
                        let updated_by = this.ownerList.find(e => e.id == this.user.userid);
                        listItem.updated_by = updated_by;
                        editItem.updated_by = updated_by;
                        listItem.updated_on = new Date();
                        editItem.updated_on = new Date();
                        this.loading_edit = false;
                    })
                    return;
                }

                listItem[prop] = value;
                editItem[prop] = value;
                let editData = JSON.parse(JSON.stringify(editItem));
                prop == 'owner' ? delete editData.stage_type : '';
                prop == 'owner' ? delete editData.closed_reason : '';
                prop == 'owner' ? delete editData.owner.usersetting : '';
                
                if(prop == "parent_case"){
                    if(value.id){
                        editData.parent_case_id = value.id;
                    }else{
                      editData.parent_case_id = null;
                    }
                }
                if(editData.case_org == null){
                    editData.case_org = {};
                }
                CaseDataService.editdetail(editData)
                .then((response)=>{
                    if(prop == "case_org"){
                        if(response.data.case_org){
                            listItem.case_org = response.data.case_org;
                        }else{
                            listItem.case_org = {
                                "id":null,
                                "name":null,
                            };
                            listItem.case_org_id = null;
                        }
                    }
                    if(prop == "primary_contact"){
                        if(Object.keys(value).length > 0){
                            listItem.primary_contact = {
                                "id":response.data.primary_contact.id,
                                "name":response.data.primary_contact.__chineseName__
                            };
                            listItem.primary_contact_id = response.data.primary_contact.id;
                            listItem.primary_contact_zh = response.data.primary_contact.full_name;
                            if(response.data.primary_contact.first_name !=""){
                                listItem.primary_contact_en = response.data.primary_contact.first_name + " " +response.data.primary_contact.last_name;
                            }else{
                                listItem.primary_contact_en = response.data.primary_contact.last_name;
                            }
                        }else{
                            listItem.primary_contact = {
                                "id":null,
                                "name": " "
                            };
                            listItem.primary_contact_id = null;
                            listItem.primary_contact_en = " ";
                            listItem.primary_contact_zh = "";
                        }
                        
                    }
                    if(prop == "parent_case"){
                        if(response.data.parent_case){
                            listItem.parent_case = {
                                "id":response.data.parent_case.id,
                                "subject":response.data.parent_case.subject,
                            };
                            listItem.parent_case_id = response.data.parent_case.id;
                        }else{
                            listItem.parent_case = {
                                "id":null,
                                "subject":null,
                            };
                            listItem.parent_case_id = null;
                        }
                    }
                    if(prop == "status"){
                        listItem.status = "";
                        listItem.statusName = "";
                        listItem.status = response.data.status;
                        listItem.statusName = this.getItemNameFromList(this.caseStatusList, listItem.status);
                    }
                    if(prop == "group"){
                        let groupName = this.caseGroupList.filter(e=>e.id == response.data.group)[0].group_name;
                        listItem.group_id = response.data.group;
                        listItem.group = groupName;
                        listItem.groupName = groupName;
                    }
                    if(prop == "type"){
                        let typeName = this.caseTypeList.filter(e=>e.id == response.data.type)[0].type_name;
                        listItem.type_id = response.data.type;
                        listItem.type = typeName;
                        listItem.typeName = typeName;
                    }
                    if(prop == 'priority_number'){
                        listItem.priority = response.data.priority;
                    }
                    let updated_by = this.ownerList.find(e => e.id == this.user.userid);
                    listItem.updated_by = updated_by;
                    editItem.updated_by = updated_by;
                    listItem.updated_on = response.data.updated_on;
                    editItem.updated_on = response.data.updated_on;
                })
                .catch((response)=>{
                    console.log(response);
                })
                .finally(()=>{
                    this.loading_edit = false;
                })
            },
            onEmitInlineEdit_cf(listItem,editItem){
                CustomFieldDataService.updateCaseCustomFieldDetail(editItem.id,editItem)
                .catch((response)=>{
                    console.log(response);
                })
                .finally(()=>{
                    let updated_by = this.ownerList.find(e => e.id == this.user.userid);
                    listItem.updated_by = updated_by;
                    editItem.updated_by = updated_by;
                    listItem.updated_on = new Date();
                    editItem.updated_on = new Date();
                    this.loading_edit = false;
                })
            },
            onEmitView(view, mode){
                let promise = new Promise((resolve)=>{
                    this.loading_content = true;
                    resolve();
                })
                promise
                .then(()=>{
                    this.currentTable = view;

                    if(mode != undefined){
                        if(mode=="edit"){
                            this.editMode = 'EDIT';
                            this.showEditTableDialog();
                        }
                        else if(mode=='copy'){
                            this.editMode = 'COPY';
                            this.showEditTableDialog();
                        }
                        else if(mode=='add'){
                            const userOwnCV = this.customViews.find(e => e.owner != null);
                            let item = {
                                name: view.name,
                                fields: view.fields,
                                is_default: false,
                                stage_type_id: this.selectStageType.id,
                                owner: userOwnCV.owner,
                                id : view.view_id
                            }
                            view.owner = userOwnCV.owner;
                            
                            userOwnCV.definition.push(item);
                            this.getHeader();
                        }
                    }else{
                        this.getHeader();
                    }
                })
                .finally(()=>{
                    setTimeout(() => {
                        this.loading_content = false;
                    }, 500);
                })
            },
            onEmitFilter(filter, situation){
                
                if(situation == 'cancelEdit'){
                    for(let prop in this.default_filter){
                        let p = prop.toString();
                        this.$set(this.default_filter, p, filter[prop]) 
                    }
                    return;
                }
                if(situation == 'applyFilter' && filter != undefined){
                    filter.owner_id = filter.owner;
                    this.default_filter = filter;
                    return;
                }
                if(situation == 'addFilter' && filter != undefined){
                    this.filter_list.push(filter);
                    this.allfilter_list.push(filter);
                    filter.owner_id = filter.owner;
                    this.default_filter = filter;
                    return;
                }
                if(situation == 'confirmEdit_exportDialog'){
                    let oldData = this.filter_list.find(e => e.id == filter.id);
                    for(let prop in oldData){
                        let p = prop.toString();
                        this.$set(oldData, p, filter[prop]) 
                    }
                    return;
                }
            },
            async changeFilter(item){
                this.default_filter = item.id
            },
            async getFilterList(){
                await CustomFilterDataService.list()
                .then((response)=>{
                    this.allfilter_list = response.data
                    this.filter_list = this.allfilter_list.filter(el => el.class_name == "Case" && el.stage_type_id == this.selectStageType.id || el.class_name == "Case" && el.stage_type_id == 'all')
                })
                .finally(()=>{
                    this.system_default_filter = this.filter_list.find(e => e.default && e.stage_type_id == this.selectStageType.id);
                })
            },
            // onResize: function () {
            //     let windowH = window.innerHeight - 200; //table高度
            //     let objH = 70;
            //     this.itemPerPage = parseInt(windowH/objH);
            // },
            listener_function(evevt){
                const params = evevt.target._params
                let e = params.e
                let current_id = params.current_id
                let selectStageType = params.selectStageType
                let selectOwner = params.selectOwner
                let dom = params.dom
                let filter = params.filter
                if(Math.round(dom.scrollTop)/(dom.scrollHeight - dom.offsetHeight)>=0.99){
                    if(e.can_roll>0 && !this.getnewData)
                    {
                    setTimeout(() => {
                    this.roll_set[current_id] = this.roll_set[current_id]+1
                    CaseDataService.kanabn_stage("kanban_stage", selectStageType.id, selectOwner.id,1,this.searchCases,this.itemPerPage,this.sortByForSearch,current_id,this.roll_set[current_id],filter).then((response)=>
                    {
                        e.case_set = e.case_set.concat(response.data);
                        e.can_roll = e.can_roll-1
                    })
                    this.getnewData = false
                    }, 500);
                    this.getnewData = true;
                    }
                }
            },
            async addScrollEvent(){
                this.roll_set = {}
                this.selectStageType.stagedef_set.forEach(e => {
                    let current_id = String(e.id)
                    let selectStageType = this.selectStageType
                    let selectOwner = this.selectOwner
                    this.roll_set[current_id] = 0
                    let dom = document.getElementById(`drag-${e.id}`);
                    let filter = this.default_filter ?  this.default_filter.id : ''
                    dom._params = { e,current_id,selectStageType,selectOwner,dom,filter }
                    dom.scrollTop = 0
                    dom.addEventListener('scroll',this.listener_function);
                })
            },
            async removeScrollEvent(){
                this.selectStageType.stagedef_set.forEach(e => {
                    let dom = document.getElementById(`drag-${e.id}`);
                    if(dom!= null){
                        dom.removeEventListener('scroll',this.listener_function)
                    }
                })
            },
            getColor: function(data) {
                this.colorQ = data	 
                return {
                    '--backgroundColor':this.colorQ,
                    '--color': this.colorQ		
                }
            },
            onMail: function (mail) {
                return "mailto:" + mail;
            },
            onCall: function (cell) {
                return "tel:" + cell;
            },
            // view
            async viewKanban() {
                if(this.mode == 'kanban')
                {
                    return
                }
                await (this.first_run = false);
                this.mode = 'kanban';
                this.stageTypeList = this.stageTypeList.filter(el => el.id != "all")
                if(this.selectStageType.id == 'all')
                {
                    this.selectStageType = this.stageTypeList[0];
                }
                await this.filterByStageTypeAndOwner();
                await this.getTables();
                await this.removeScrollEvent();
                await this.addScrollEvent();
                await (this.first_run = true);
            },
            async viewList() {
                if(this.mode == 'list')
                {
                    return
                }
                await (this.first_run = false);
                this.mode = 'list';
                this.stageTypeList.unshift({"id":"all","stage_type_name":i18n.t('GENERAL.ALL')})
                await this.filterByStageTypeAndOwner();
                await this.getTables();
                await (this.first_run = true);
                // await this.removeScrollEvent();
            },
            async filterByStageTypeAndOwner(){
                const current_mode = this.mode;
                // check if case has stage_type 
                if(this.selectStageType.stage_type_name == i18n.t('CASE.NO_CASE_STAGE'))
                {
                    return
                }
                // check user input data is from combobox
                let flag1, flag2, flag3;

                typeof(this.selectStageType) == 'object' ? flag1 = true : flag1 = false;
                typeof(this.currentTable) == 'object' ? flag2 = true : flag2 = false;
                typeof(this.selectOwner) == 'object' ? flag3 = true : flag3 = false;

                if(flag1 && flag2 && flag3){
                    this.loading_content = true;
                    let filter = this.default_filter ?  this.default_filter.id : '';
                    await CaseDataService.kanabn(this.mode, this.selectStageType.id, this.selectOwner.id,this.page,this.searchCases,this.itemPerPage,this.sortByForSearch,filter)
                    .then(response => {
                        if(current_mode != this.mode){
                            return;
                        }
                        if(this.mode == 'list'){
                            this.caseList = response.data.list;
                            this.pageCount = response.data.total_page
                            this.pageDataCount = response.data.pageDataCount
                            this.currentData = (this.page)*this.itemPerPage >= this.pageDataCount ? this.pageDataCount:(this.page)*this.itemPerPage	
                            this.total_cases = response.data.total_cases
                            this.caseList.forEach(caseItem => {
                                caseItem.current_stage = {'stage_name':caseItem.stage_name}
                                caseItem.groupName = this.getItemFromListById(this.caseGroupList, caseItem.group_id).group_name;
                                caseItem.typeName = this.getItemFromListById(this.caseTypeList, caseItem.type_id).type_name;
                                caseItem.statusName = this.getItemNameFromList(this.caseStatusList, caseItem.status);
                                caseItem.priorityName = this.getItemNameFromList(this.casePriorityList, caseItem.priority_number);
                                caseItem.owner.full_name = caseItem.owner.first_name != null ? caseItem.owner.last_name + ' ' + caseItem.owner.first_name : caseItem.owner.last_name;
                            });
                        }
                        else if(this.mode == 'kanban'){
                            this.selectStageType = response.data;
                        }
                    })
                    .catch(response => {
                        this.showErrorDialog(response);
                    })
                    // this.sortByForSearch = []
                }
            },
            get_current_filter(){
                let filter_id = this.getDefaultFilter("Case",this.selectStageType.id)
                this.filter_list = this.allfilter_list.filter(el => el.class_name == "Case" && el.stage_type_id == this.selectStageType.id || el.class_name == "Case" && el.stage_type_id == 'all')
                this.system_default_filter = this.filter_list.find(e => e.default);
                this.default_filter = this.getPageDefaultFilter(filter_id,this.filter_list,this.system_default_filter)
            },
            async reloadStageTypeAndData() {
                // check user input data is from combobox
                if(typeof(this.selectStageType) == 'object' && typeof(this.selectOwner) == 'object'){
                    if(this.mode == "list"){
                        this.changeListStage = true;
                    }
                    await this.get_current_filter()
                    await this.filterByStageTypeAndOwner();
                    await this.getTables();
                    await (this.changeListStage = false);
                    if(this.mode == "kanban"){
                        await this.removeScrollEvent();
                        await this.addScrollEvent();
                    }
                }
            },
            async reloadData(){
                if(typeof(this.selectStageType) == 'object' && typeof(this.selectOwner) == 'object'){
                    await this.filterByStageTypeAndOwner();
                    this.loading_content = false;
                    if(this.mode == "kanban"){
                        await this.removeScrollEvent();
                        await this.addScrollEvent();
                    }
                }
            },
            async dragstart(item){
                this.canDrag_kanban= false;
                this.dragStageIndex = this.getIndex(this.selectStageType.stagedef_set, item);
                this.showResult = true;
                this.checkConsoleLog(["dragstart",this.canDrag_kanban,this.dragStageIndex,this.showResult,item]);
                await (this.updateCase =  item);
            },
            async dragEnd(){
                this.checkConsoleLog(["dragEnd",this.winLost]);
                if(!this.winLost)
                {
                    this.loading_content = true;
                    let newStage = await this.selectStageType.stagedef_set.find(stage => stage.case_set.find(c => c.id == this.updateCase.id));
                    let stageData = await { "current_stage_id": newStage.id };
                    this.checkConsoleLog(["dragEnd",newStage,stageData]);
                    await CaseDataService.updatestage(this.updateCase.id, stageData).then(()=>{
                        this.checkConsoleLog(["dragEnd","update",this.selectStageType.stagedef_set[this.dragStageIndex]]);
                        this.selectStageType.stagedef_set[this.dragStageIndex].case_count = this.selectStageType.stagedef_set[this.dragStageIndex].case_count -1
                        newStage.case_count = newStage.case_count + 1
                        this.checkConsoleLog(["dragEnd","update over"]);
                    })
                    .catch(response => {
                        this.checkConsoleLog(["dragEnd","error",response]);
                        this.showErrorDialog(response);
                    })
                    .finally(()=>{
                        this.canDrag_kanban = true;
                        this.loading_content = false;
                        this.checkConsoleLog(["dragEnd","finally",this.canDrag_kanban,this.loading_content]);
                    })
                }
                else{
                    this.winLost = false;
                    this.checkConsoleLog(["dragEnd","is winlost",this.winLost]);
                }
                this.showResult = false;
                this.checkConsoleLog(["dragEnd over"]);
            },
            async getTables(){
                this.tables = [];
                this.customViews.forEach(e => {
                    if(e.owner == null){
                        let item = {
                            id: e.id,
                            name: e.definition[0].name,
                            is_default: false,
                            fields: e.definition[0].fields,
                            owner: e.owner,
                            view_id : e.definition[0].id,
                        }
                        
                        let index;
                        if(e.definition[0].is_default == true){
                            item.is_default = true;
                            this.currentTable = item;
                        }
                        else{
                            index = e.definition[0].is_default.indexOf(String(this.selectStageType.id))
                            if(index != -1){
                                this.currentTable = item;
                            }
                        }
                        this.currentTable.view_id = e.definition[0].id;
                        this.tables.push(item)
                    }
                    else if(e.owner != null){
                        e.definition.forEach(table => {
                            if(table.stage_type_id == this.selectStageType.id){
                                let item = {
                                    id: e.id,
                                    name: table.name,
                                    is_default: table.is_default,
                                    fields: table.fields,
                                    owner: e.owner,
                                    view_id : table.id,
                                }
                                if(item.is_default){
                                    this.currentTable = item;
                                }
                                this.tables.push(item)
                            }
                        })
                    }
                });
                this.tables.reverse()
                this.getHeader();
            },
            async getHeader(){
                await this.getCFdef();
                let customWidthData = await this.getWidth("Case",this.currentTable);
                let cfKey = this.customField ? Object.keys(this.customField) : [];
                // check user input is from combobox
                let flag1, flag2, flag3;

                typeof(this.selectStageType) == 'object' ? flag1 = true : flag1 = false;
                typeof(this.currentTable) == 'object' ? flag2 = true : flag2 = false;
                typeof(this.selectOwner) == 'object' ? flag3 = true : flag3 = false;

                if(flag1 && flag2 && flag3){
                    await (this.loading_content = true);
                    let array = [];
                    let select = {
                        value: "data-table-select",
                        align: "center",
                        width: '100px',
                    };
                    let action = {
                        text: i18n.t('GENERAL.ACTIVE') ,
                        value: "action",
                        align: "center",
                        width: '150px',
                        sortable: false,
                    };
                    
                    this.currentTable.fields.forEach(e => {
                        let f_value = (e.value == "priority") ? "priority_number" : e.value;
                        let item = {
                            text: '',
                            value: f_value,
                            sequence: e.sequence_number,
                            // width: '200px',
                            align: 'center',
                            sortable: true
                        };

                        // if(this.currentTable.fields.length >= 8){
                        //     item.width = '200px';
                        // }

                        if(e.is_customfield && this.CFdef != null){
                            if(this.CFdef[e.value] != undefined){
                                item.text = e.text;
                                item.value = 'custom_field_' + e.value;
                                item.prop = e.value;
                                item.array_num = this.CFdef[e.value].array_num;
                                item.type = (e.value).substr(0,3)
                                this.cf_header.push(item);
                            }
                        }
                        else{
                            item.text = this.getItemNameFromList(casesFieldList , e.value);
                        }
                        if(e.is_customfield && cfKey.indexOf(e.value)!=-1){
                            array.push(item);
                        }
                        if(!e.is_customfield){
                            array.push(item);
                        }
                        // array.push(item);
                    })
                    array.sort(function(a, b){
                        return a.sequence_number - b.sequence_number
                    });
                    array.unshift(select);
                    array.push(action);

                    this.casesHeaders = array;
                    this.sortByDesc = [];
                    this.sortBy = [];
                    this.headerData.id = this.currentTable.view_id;
                    this.headerData.name = this.currentTable.name;
                    this.headerData.value = array;
                    this.headerData.page = "Case";
                    this.headerData.customWidth = customWidthData;
                    await (this.loading_content = false);
                    this.loading = false;
                    const title = localStorage.getItem('title');
                    document.title = title+' : '+this.currentTable.name +' – UpDay';
                }
            },
            getHeaderwithWidth(data){
                this.casesHeaders = data;
            },
            async getCFdef(){
                let cfDefList = []
                let treeSelectData = [];
                await TreeOptionsDataService.list().then((response)=>{
                    treeSelectData = response.data;
                })
                await CustomFieldDataService.getCustomFieldDetail("Case")
                .then((response)=>{
                   if(response.data[0].definition != null && response.data[0].definition.length >0){
                        cfDefList = response.data[0].definition.filter(el=>el.stage_type_id == this.selectStageType.id || el.stage_type_id == "all");
                    }
                    if(cfDefList.length>0){
                        this.customField = {};
                        for(let i in cfDefList){
                            for(let c in cfDefList[i].data){
                                cfDefList[i].data[c]['array_num'] = parseInt(i)
                            }
                            this.customField = Object.assign(this.customField,cfDefList[i].data);
                            this.CFdef = this.customField;
                        }
                        for(let prop in this.CFdef){
                            if(prop.includes('tst')){
                                let treeData = treeSelectData.filter(el=>el.id == this.CFdef[prop].tree_select_id)[0]
                                let optList = treeData.option_set;
                                optList = Object.entries(optList).map((arr) => (
                                {
                                    key: arr[0],
                                    name: arr[1],
                                }));
                                this.CFdef[prop]['optList'] = optList;
                                this.CFdef[prop]['treeList'] = treeData.value;
                            }
                            if(prop.includes('opt') || prop.includes('mlt')){
                                let optList = Object.entries(this.CFdef[prop].option_set)
                                .map((arr) => (
                                {
                                    key: arr[0],
                                    name: arr[1],
                                }));
                                this.CFdef[prop]['optList'] = optList;
                            }
                        }
                    }
                })
            },
            // Dialog
            showCopyCaseDialog(rowData) {
                this.addCaseData = {
                    subject: rowData.subject,
                    primary_contact: {
                        "id": rowData.primary_contact.id,
                        "full_name": rowData.primary_contact.name
                    },
                    case_org: rowData.case_org_id? 
                    {
                        "id": rowData.case_org.id,
                        "name": rowData.case_org.name,
                        "company_number": null,
                    }: null,
                    source: rowData.source,
                    group: rowData.group_id,
                    type: rowData.type_id,
                    category: rowData.category_id,
                    due_date: rowData.due_date,
                    current_stage: rowData.sequence_number,
                    stage_type: {
                        id: rowData.stage_type_id,
                        stage_type_name: rowData.stage_type_name,
                        stagedef_set: {
                            id: rowData.stage_def_id,
                            sequence_number: rowData.sequence_number,
                            stage_name: rowData.stage_name,
                            stage_color: rowData.stage_color
                        }
                    },
                    incoming_phone:rowData.incoming_phone,
                    incoming_email:rowData.incoming_email,
                    parent_case:rowData.parent_case.id?
                    {
                        "id": rowData.parent_case.id,
                        "subject": rowData.parent_case.subject,
                    }:null,
                    custom_field:rowData.custom_field,
                }
                this.addCaseDialog = true;
            },
            showAddCaseDialog(data) {
                if(this.selectStageType.stage_type_name == i18n.t('CASE.NO_CASE_STAGE')){
                    this.showErrorDialog(i18n.t('CASE.ERROR'))
                }
                else{
                    if (data == undefined) {
                        this.addCaseData = {
                            subject: '',
                            primary_contact: '',
                            incoming_phone:'',
                            incoming_email:'',
                            case_org: null,
                            source: '',
                            group: '',
                            type: '',
                            category: '',
                            due_date: '',
                            current_stage: 0,
                            stage_type: this.selectStageType,
                        }
                    } 
                    else {
                        this.addCaseData = {
                            subject: '',
                            primary_contact: '',
                            case_org: null,
                            source: '',
                            group: '',
                            type: '',
                            category: '',
                            due_date: '',
                            current_stage: data.sequence_number,
                            stage_type: this.selectStageType,
                        }
                    }
                    this.addCaseDialog = true;
                }
                
            },
            onEmitAddCaseDialog(val) {
                this.addCaseDialog = false;
                this.selectedRows = [];
                if (val) {
                    this.showSuccessDialog();
                }
            },
            onEmitNewCaseData(data) {
                let promise = new Promise((resolve) => {
                    if(this.selectOwner.id != 0 && this.selectOwner.id != data.owner.id)
                    {
                        return
                    }
                    this.loading_content = true;
                    data.current_stage = {'stage_name':data.stage_name}
                    data.groupName = this.getItemFromListById(this.caseGroupList, data.group_id).group_name;
                    data.typeName = this.getItemFromListById(this.caseTypeList, data.type_id).type_name;
                    data.statusName = this.getItemNameFromList(this.caseStatusList, data.status);
                    data.priorityName = this.getItemNameFromList(this.casePriorityList, data.priority_number);
                    data.owner.full_name = data.owner.first_name != null ? data.owner.last_name + ' ' + data.owner.first_name : this.pageDataCount.owner.last_name;
                    resolve();
                })

                promise
                .then(()=>{
                    if(this.mode == 'kanban'){
                    let stage = this.selectStageType.stagedef_set.find(stage => stage.id == data.current_stage_id);
                    stage.case_set.unshift(data);
                    stage.case_count ++;
                    } 
                    else if(this.mode == 'list'){
                        if(this.selectStageType.id == data.stage_type || this.selectStageType.id == 'all'){
                            // if(data.group){
                            //     data.groupName = this.caseGroupList.filter(el=>el.id == data.group)[0].group_name
                            // }
                            // if(data.type){
                            //     data.typeName = this.caseTypeList.filter(el=>el.id == data.type)[0].type_name
                            // }
                            // let stage_type = this.stageTypeList.filter(el=>el.id == data.stage_type)[0]
                            // data.stage_type_name = stage_type.stage_type_name
                            // data.current_stage.stage_name = stage_type.stagedef_set.filter(el=>el.id == data.current_stage_id)[0].stage_name
                            // data.updated_by = data.owner;
                            // data.created_by = data.owner;
                            // this.caseList.unshift(data)
                            // this.pageDataCount ++;
                            // this.selectStageType.case_count ++;
                            this.reloadData();
                        }
                    }
                })
                .then(()=>{
                    this.loading_content = false;
                })
            },
            async showToCaseDialog(item) {
                this.toCaseDialog = true
                this.caseData = item;
            },
            onEmitToCaseDialog(data) {
                this.hadEditToCase = data
                this.toCaseDialog = false;
            },
            showAddStageDialog() {
                this.pageType = 'CASE'
                this.stageTypeData = {
                    stage_type_name: '',
                    type:null                    
                }
                this.stageDefData = [
                    {
                        sequence_number: '',
                        stage_name: '',
                        stage_probability: '',
                        zombie_day: '',
                        guideline: '',
                        stage_color: '',
                        isMore: false,
                    }
                ]
                this.addProcessDialog = true;
            },			
            onEmitAddProcessDialog(val){
                if (val) {
                    this.showSuccessDialog();
                }
                this.addProcessDialog = false;
            },
            onEmitAddProcessData(data){ 
                this.stageTypeList.push(data)
                if (this.stageTypeList[0].id == undefined) {
                    this.stageTypeList.splice(0, 1);
                    this.selectStageType = this.stageTypeList[0];
                    this.filterByStageTypeAndOwner();
                    this.getTables();
                }
            },
            showSuccessDialog() {
                this.successDialog = true;
                this.winLost = false
            },
            async onEmitSuccessDialog() {
                this.successDialog = false;				
            },
            onDelete (item) {
                this.deleteCaseIds = "";
                if(item!=null){
                    this.deleteItem = item;
                    this.msgDialog_mode = 'delete';
                    this.showMessageDialog(i18n.t('CASE.MESSAGE'));
                }else{
                    let closeCase = '';
                    for(let i = 0; i < this.selectedRows.length; i++)
                    {
                        this.deleteCaseIds += `${this.selectedRows[i].id},`;
                        if(this.selectedRows[i].closed_reason != null){
                            closeCase = closeCase.length > 0 ? closeCase +','+ this.selectedRows[i].subject : closeCase + this.selectedRows[i].subject
                        }
                    }
                    this.deleteCaseIds = this.deleteCaseIds.slice(0,-1);
                    if(closeCase.length>0){
                        this.showErrorDialog(`${i18n.t('CASE.DELETE_ERROR')}<br>${closeCase}`);
                    }else{
                        this.showMessageDialog(i18n.t('CASE.MESSAGE'));
                    }
                    }
            },
            showMessageDialog(message) {
                this.messageDialog = true;
                this.message = message;
            },
            onEmitMessageDialog(val) {
                this.messageDialog = false;
                if (val && this.deleteCaseIds == "") { 
                    // 如果messageDialog是在刪除時被觸發
                    if(this.msgDialog_mode == 'delete'){
                        CaseDataService.delete(this.deleteItem.id)
                        .then(() => {
                            // 如果現在是在list頁面
                            if(this.mode == 'list'){
                                let index = this.caseList.indexOf(this.deleteItem);
                                this.caseList.splice(index, 1);
                            }
                            this.successDialog = true;
                            this.selectStageType.case_count --;
                            this.pageDataCount --;
                            this.selectedRows = [];
                        })
                        .catch(response => {
                            this.showErrorDialog(response)
                        })
                    }else{
                        this.successDialog = true; 
                    }
                    
                }else if(val && this.mode == 'list' && this.deleteCaseIds != ""){
                    CaseDataService.bulkdelete(this.deleteCaseIds)
                    .then(() => {
                        this.deleteCaseIds = "";
                        this.reloadData();
                        this.selectedRows = [];
                    })
                }
            },
            showErrorDialog(message) {
                this.errorDialog = true;
                this.errorMessage = message;
            },
            onEmitErrorDialog() {
                this.errorDialog = false;
            },
            async winDrop() {
                this.winLost = true
                this.loseDealData = this.winList[0];
                this.loseDealData.case_id = this.loseDealData.id
                this.loseDealData.reason = "RESOLVED"
                this.loseDealData.closed_date = this.getDate()		
                this.showResult = false;
                this.loseReason = true;
                this.loseList = [];
                this.canDrag_kanban = true;
            },
            loseDrop() {
                this.winLost = true
                this.loseDealData = this.loseList[0];
                this.loseDealData.case_id = this.loseDealData.id
                var today = this.getTodayDate();
                this.loseDealData.closed_date = today;
                this.showResult = false;
                this.loseReason = true;
                this.loseList = [];
                this.canDrag_kanban = true;
            },
            getDate(){
                var fullDate = new Date();
                var yyyy = fullDate.getFullYear();
                var MM = (fullDate.getMonth() + 1) >= 10 ? (fullDate.getMonth() + 1) : ("0" + (fullDate.getMonth() + 1));
                var dd = fullDate.getDate() < 10 ? ("0"+fullDate.getDate()) : fullDate.getDate();
                var today = yyyy + "-" + MM + "-" + dd;
                return today;
            },
            getTodayDate() {
                var fullDate = new Date();
                var yyyy = fullDate.getFullYear();
                var MM = (fullDate.getMonth() + 1) >= 10 ? (fullDate.getMonth() + 1) : ("0" + (fullDate.getMonth() + 1));
                var dd = fullDate.getDate() < 10 ? ("0"+fullDate.getDate()) : fullDate.getDate();
                var HH = fullDate.getHours() >= 10 ? fullDate.getHours() : ("0" + fullDate.getHours());
                var mm = fullDate.getMinutes() >= 10 ? fullDate.getMinutes() : ("0" + fullDate.getMinutes());
                var today = yyyy + "-" + MM + "-" + dd + " " + HH + ":" + mm;
                return today;
            },
            onEmitLoseReasonDialog(val) {
            this.loseReason = false;
                if (!val) {
                    delete this.loseDealData.case_id;
                    delete this.loseDealData.reason;
                    delete this.loseDealData.closed_date;
                    this.selectStageType.stagedef_set[this.dragStageIndex].case_set.push(this.loseDealData);
                }else{
                    this.loseReason = false;
                    if(this.mode=='list')
                    {
                        this.caseList.length = this.caseList.length -1;
                    }else{
                        this.selectStageType.case_count = this.selectStageType.case_count -1;
                        this.selectStageType.stagedef_set[this.dragStageIndex].case_count = this.selectStageType.stagedef_set[this.dragStageIndex].case_count - 1;
                    }
                }
            },
            getIndex(array, obj) {
                let index = null
                for(let i in array){
                    array[i].case_set.findIndex(item => {
                        if(item.id === obj.id){
                        index =  i
                        }
                });
                }
                return index;
            },
            showEditTableDialog(){
                this.editTableDialog = true;
            },
            onEmitEditTableDialog(val){
                if(val){
                    this.showSuccessDialog();
                }
                this.editTableDialog = false;
            },
            onEmitEditTable(data){
                if(this.editMode == 'EDIT'){
                    this.currentTable.name = data.name;
                    this.currentTable.fields = data.fields;
                }
                else if(this.editMode == 'COPY'){
                    this.tables.unshift(data);
                    this.currentTable = data;
                }
                this.getHeader();
            },
            async onEmitRefresh(){
                this.reloadData();
            },
            showBulkEditDialog(){
                this.bulkEditDialog = true;
            },
            async onEmitBulkEditDialog(val,editData){
                this.bulkEditDialog = false;
                if(val){
                    if(val == 'editing'){
                        this.loading = true;
                        await BulkEditDataService.editCase(editData);
                        await(this.selectedRows = []);
                        await(this.filterByStageTypeAndOwner());
                        await(this.loading = false);
                    }
                }
            },
        },
        watch:{
            "default_filter": async function(){
                if(this.first_run && !this.changeListStage){
                    this.page = 1;
                    await this.filterByStageTypeAndOwner();
                    if(this.mode == "kanban"){
                        await this.removeScrollEvent();
                        await this.addScrollEvent();
                    }
                    await (this.loading_content = false);
                    await (this.loading = false);
                }
            },
            toCaseDialog: async function(){
                if(this.toCaseDialog == false)
                {
                    if(this.hadEditToCase){
                        await this.filterByStageTypeAndOwner()
                        await (this.loading_content = false);
                    }
                }
            },
            "page":async function () {
                await this.filterByStageTypeAndOwner()
                this.loading_content = false;
                this.loading = false;
            },
            "searchCases":async function () {
                if (!this.awaitingSearch) {
                setTimeout(() => {
                    this.page = 1
                    this.filterByStageTypeAndOwner();
                    this.awaitingSearch = false;
                    this.loading_content = false;
                }, 1000);
                }
                this.awaitingSearch = true;
            },            
            "sortByDesc":async function () {
                if(this.sortByDesc.length != 0){
                    this.sortByForSearch = JSON.parse(JSON.stringify(this.sortBy))
                    for(let i in this.sortByDesc)
                    {
                        if(this.sortByDesc[i] == true)
                        {
                            this.sortByForSearch[i] = '-'+this.sortByForSearch[i]
                        }
                    }
                    await this.filterByStageTypeAndOwner()
                    this.loading_content = false;
                }
            },
            "loading": async function(){
                if(!this.loading && this.first_run){
                    await this.filterByStageTypeAndOwner();
                    if(this.mode == "kanban"){
                        await this.removeScrollEvent();
                        await this.addScrollEvent();
                    }
                    await (this.loading_content = false);
                    await (this.loading = false);
                }
            },
            "selectStageType":async function(){
                if(this.mode == 'list'){
                    this.sortByForSearch = [];
                }

                if(this.selectStageType.id != 'all'){
                    try{
                        this.selectStageTypeList = this.selectStageType.stagedef_set;
                    }catch{
                        this.selectStageTypeList = [];
                    }
                }
            },
            "currentTable":async function(){
                this.sortByForSearch = [];
            }
        }
    }
</script>